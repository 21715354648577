import React from "react"
import styled from "styled-components"
import FilmStrip from "../images/invite_pics.jpg"
import Proposal from "../images/proposal.jpg"
import LeafBorder from "../images/hand-painted-leaf-border-cut.png"
import Layout from "../components/layout"
import SEO from "../components/seo"

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 120px;
  margin-bottom: 32px;
  background-size: 120px 30px;
`

const H1 = styled.h1`
  margin-bottom: 6px;
`

const FilmStripPicture = styled.div`
  background-image: url("${FilmStrip}");
  text-align: center;
  height: 200px;
  background-size: auto 200px;
  background-position: 50%;
  margin-bottom: 26px;
  @media (max-width: 768px) {
    height: 100px;
    background-size: auto 100px;
  }
`

const ProposalPicture = styled.div`
  background-image: url("${Proposal}");
  background-repeat: no-repeat;
  background-position: "top";
  width: 400px;
  height: 300px;
  background-size: cover;
  margin: 40px auto;
  @media (max-width: 768px) {
    background-size: contain;
    max-width: 100%;
    display: inline-block;
  }
`

const ProposalPictureContainer = styled.div`
  text-align: center;
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

class AboutUsPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="About us | Sara & Tim" />
        <H1>ABOUT US</H1>
        <LeafBorderPicture />
        <FilmStripPicture></FilmStripPicture>
        <P>
          For those who, somehow, might not know who we are - we're Sara and
          Tim. Here's a bit of exposition so you can learn some more about us
          and how this wedding came to be.
        </P>
        <P>
          It started with a gig... probably the worst gig that anyone has ever
          been to. It was early 2014 and AWOLNATION had just emerged from
          anonymity, shooting rapidly to stardom with their anthemic record
          "Sail". Tim's good friend, Lorna, naively expecting the rest of their
          repertoire to be of similar quality, enthusiastically badgered him to
          see them live. Thankfully, she managed to convince an equally trusting
          Sara to come along too.
        </P>
        <P>
          After running out of the concert early, the evacuees enjoyed a
          frivolous night of merry-making, dancing and getting to know each
          other better. This was followed by a hungover brunch, replete with jam
          and eggs, Donkey Kong and a long walk (an early glimpse of the good
          times to come!).
        </P>
        <P>
          The budding relationship officially blossomed with Sara's visit to
          Brighton several weeks later, nurtured in no small part by the fresh
          sea air, golden sunshine and cheap wine.
        </P>
        <P>
          Snapshot memories of the past six years: weekend trains between London
          and the coast, sharing long road trips and tents, trying to make good
          first impressions with friends and family, shivering in Romania's
          punishing winter, discovering Canada and cinnamon buns, exploring
          Wilderness, moving in together (gotta love Finny P), pub quizzing and
          partying, travelling (South Korea, Portugal and Greece, to name a
          few), battling "the PhD", buying a flat (in London!) and then...
        </P>
        <P>THE PROPOSAL...</P>
        <P>
          And the realisation that life is better together.{" "}
          <span role="img" aria-label="heart-emoji">
            ❤️
          </span>
        </P>
        <P>We are ready to celebrate and party. We hope you are too!</P>
        <ProposalPictureContainer>
          <ProposalPicture />
        </ProposalPictureContainer>
      </Layout>
    )
  }
}

export default AboutUsPage
